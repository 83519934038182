import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import "../../stylesheets/FooterDE.css";
import LI from "../../assets/linkedin.svg";

class FooterDE extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="flex center-h">
        <div className="section footer">
          <div className="bottom-divider footer"></div>
          <div className="flex spa">
            <div className="flex footer-third col">
              <span className="footer-header">Erfahre mehr</span>
              <Link to="/de/news">
                <span>News</span>
              </Link>
              <Link to="/de/ueber-uns">
                <span>Über Uns</span>
              </Link>
              <Link to="/de/referenzen">
                <span>Referenzen</span>
              </Link>
              <Link to="/de/kontakt">
                <span>Kontakt</span>
              </Link>
            </div>
            <div className="flex footer-third col">
              <span className="footer-header">Zertifikate</span>
              <Link to="/de/umwelt#intro">
                <span>REACH</span>
              </Link>
              <Link to="/de/umwelt#coc">
                <span>Code of Conduct</span>
              </Link>
              <Link to="/de/umwelt#rohs">
                <span>RoHS</span>
              </Link>
              <Link to="/de/umwelt#iatf-iso">
                <span>IATF</span>
              </Link>
              <Link to="/de/umwelt#iatf-iso">
                <span>ISO 9001</span>
              </Link>
            </div>
            <div className="flex footer-third col">
              <span className="footer-header">Rechtliches</span>
              <Link to="/de/impressum">
                <span>Impressum</span>
              </Link>
              <Link to="/de/datenschutzerklaerung">
                <span>Datenschutzerklärung</span>
              </Link>
            </div>
          </div>
          <div className="bottom-divider footer"></div>
          <div className="flex center-h col center-v">
            <span>
              ©2024 Pancon GmbH
            </span>
            <a className="marg-top-10" href="https://www.linkedin.com/company/pancon-connectors/?originalSubdomain=de" target="_blank">
              <img height="32px" src={LI}></img>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterDE;
