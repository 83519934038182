import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";
import LL from "../../assets/Lumex.png";
import C1 from "../../assets/c1.JPG";
import C2 from "../../assets/c2.JPG";
import C3 from "../../assets/c3.JPG";
import C4 from "../../assets/c4.JPG";
import C5 from "../../assets/c5.JPG";
import C6 from "../../assets/c6.JPG";
import C7 from "../../assets/c7.JPG";
import C8 from "../../assets/c8.JPG";
import VID1 from "../../assets/vid1.mp4"
import VID2 from "../../assets/vid2.mp4"
import ReactPlayer from 'react-player'
import LUMEX1 from "../../assets/PAN_PRO_Kompetenzfolder_Lumex-Switches.pdf";
import LUMEX2 from "../../assets/PAN_PRO_Kompetenzfolder_Lumex-LED.pdf";

class LUMEX extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - LUMEX</title>
          <meta
            name="description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <meta property="og:title" content="Pancon - LUMEX" />
          <meta
            property="og:description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
        </MetaTags>
        <NavDE />
        <div className="section np flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Lumex - Best in class LED, LCD & Switches</h2>
            </div>
            <div className="half">
              <p className="body">
                We are pleased to announce that we are the new sales representative for
                European countries. To support the various needs of LEDs, LCDs or Switches from customers in Europe.
                <br></br><br></br>Sie erreichen unseren
                Customer Service unter:{" "}
                <a
                  className="accented"
                  href="mailto:customer-service@pancon.de"
                >
                  customer-service@pancon.de
                </a>
              </p>
              <div className="full m-top-2">
                <a href="https://www.lumex.com/" target="_blank">
                  <button onClick={this.sendMail} className="default">
                    For more technical details
                  </button>
                </a>
              </div>
              <div className="flex sonstiges-2">
                <a
                  target="_blank"
                  href={LUMEX1}
                >
                  <div className="flex marg-top">
                    <button className="default">LUMEX Switches</button>
                  </div>
                </a>
                <a
                  target="_blank"
                  href={LUMEX2}
                >
                  <div className="flex marg-top">
                    <button className="default">LUMEX LED</button>
                  </div>
                </a>
              </div>
            </div>
            <div className="half">
              <img className="img-full" src={LL}></img>
            </div>
          </div>
        </div>
        <div className="section np flex center-h">
          <div className="medium flex">
            <div className="quart">
              <img className="img-full" src={C1}></img>
            </div>
            <div className="quart">
              <img className="img-full" src={C2}></img>
            </div>
            <div className="quart">
              <img className="img-full" src={C3}></img>
            </div>
            <div className="quart">
              <img className="img-full" src={C4}></img>
            </div>
          </div>
        </div>
        <div className="section np flex center-h">
          <div className="medium flex">
            <div className="half center">
              <ReactPlayer
                className='react-player fixed-bottom'
                url={VID1}
                width='95%'
                height='100%'
                controls={true}
              />
            </div>
            <div className="half center">
              <ReactPlayer
                className='react-player fixed-bottom'
                url={VID2}
                width='95%'
                height='100%'
                controls={true}
              />
            </div>
          </div>
        </div>
        <div className="section np flex center-h">
          <div className="medium flex center">
            <p className="body">
              ITW Lumex is a division of Illinois Tool Works Inc (ITW), a global manufacturer of
              industrial products and equipment.
              The division specializes in the design and production of LED and lighting products
              and switches for various applications.
            </p>
            <br></br>
            <p className="body">
              Panel-mount indicators, light guides and LED indicators, as well as pushbutton switches, micro switches, DIP switches and touch switches are state of art product portfolio.
            </p>
            <br></br>
            <p className="body">
              The company is known for its high-quality products for a wide range of industries,
              including automotive, aerospace, medical and telecommunications. The company's
              products are synonymous with durability, reliability and precision.
            </p>
            <br></br>
            <p className="body">
              In addition, ITW Lumex always offers design and engineering support to meet as
              many customer needs as possible. Individual product customization is also
              supported here in all areas.
            </p>
            <br></br>
            <p className="body">
              ITW Lumex has production facilities and sales offices around the world. The
              company is strongly committed to sustainability and has implemented numerous
              initiatives to reduce waste, conserve resources and minimize environmental
              impact.
            </p>
            <br></br>
            <p className="body">
              Overall, ITW Lumex is a respected and trusted provider of lighting and switch
              solutions for industrial applications, focused on quality, innovation and customer
              satisfaction.
            </p>
          </div>
        </div>
        <div className="section np flex center-h">
          <div className="medium flex">
            <div className="quart">
              <img className="img-full" src={C5}></img>
            </div>
            <div className="quart">
              <img className="img-full" src={C6}></img>
            </div>
            <div className="quart">
              <img className="img-full" src={C7}></img>
            </div>
            <div className="quart">
              <img className="img-full" src={C8}></img>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default LUMEX;
