import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import "../../stylesheets/FooterDE.css";

class FooterDE extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="flex center-h">
        <div className="section footer">
          <div className="bottom-divider footer"></div>
          <div className="flex spa">
            <div className="flex footer-third col">
              <span className="footer-header">Learn more</span>
              <Link to="/en/news?bla=test">
                <span>News</span>
              </Link>
              <Link to="/en/about-us">
                <span>About Us</span>
              </Link>
              <Link to="/en/references">
                <span>References</span>
              </Link>
              <Link to="/en/contact">
                <span>Contact</span>
              </Link>
            </div>
            <div className="flex footer-third col">
              <span className="footer-header">Certificates</span>
              <Link to="/en/environment#intro">
                <span>REACH</span>
              </Link>
              <Link to="/en/environment#coc">
                <span>Code of Conduct</span>
              </Link>
              <Link to="/en/environment#rohs">
                <span>RoHS</span>
              </Link>
              <Link to="/en/environment#iatf-iso">
                <span>IATF</span>
              </Link>
              <Link to="/en/environment#iatf-iso">
                <span>ISO 9001</span>
              </Link>
            </div>
            <div className="flex footer-third col">
              <span className="footer-header">Legal</span>
              <Link to="/en/imprint">
                <span>Imprint</span>
              </Link>
              <Link to="/en/privacy-policy">
                <span>Privacy Policy</span>
              </Link>
            </div>
          </div>
          <div className="bottom-divider footer"></div>
          <div className="flex center-h">
            <span>
              ©2024 Pancon GmbH
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterDE;
