import Hamburger from "hamburger-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/pancon_logo.png";
import "../../stylesheets/NavDE.css";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

class NavEN extends Component {
  constructor(props) {
    super(props);
    this.state = { open: "closed" };
    this.searchInput = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.menuClick = this.menuClick.bind(this);
  }

  handleSubmit(event) {
    let searchValue = this.searchInput.current.value;
    if (searchValue) {
      window.open("https://www.pancon.de/en/products?search=" + searchValue);
    }
    event.preventDefault();
  }

  productMenu(e) {
    if (e.value === "All Products") {
      window.open("https://www.pancon.de/en/products", "_self");
    } else if (e.value === "Accessories & Tools") {
      window.open("https://www.pancon.de/en/accessories-and-tools", "_self");
    } else if (e.value === "Change Notifications") {
      window.open("https://www.pancon.de/en/pcn", "_self");
    } else {
      window.open("https://www.pancon.de/en/product-overview", "_self");
    }
  }

  menuClick(e) {
    let currentState = this.state.open;
    if (currentState === "open") {
      this.setState({ open: "closed" });
    } else if (currentState === "closed") {
      this.setState({ open: "open" });
    }
  }

  render() {
    const options = [
      'All Products', 'Accessories & Tools', 'Product Overview', 'Change Notifications'
    ];
    return (
      <nav>
        <div className="section flex nav-upper">
          <div className="part-45 flex center-v left">
            <Link className="flex center-v left" to="/en/home">
              <img className="nav-logo" src={logo}></img>
              <div>
                <span>PANCON</span>
                <br></br>
                <span>CONNECTORS</span>
              </div>
            </Link>
          </div>
          <div className="tenth flex center-v center-h">
            <Link to="/">
              <span className="font-m">DE</span>
            </Link>
            <span className="font-m m-left-1">|</span>
            <span className="font-m m-left-1 active">EN</span>
          </div>
          <div className="part-45 search-container flex center-v right">
            <div className="hamburger-cont">
              <Hamburger onToggle={this.menuClick} />
            </div>
            <form onSubmit={this.handleSubmit}>
              <input
                ref={this.searchInput}
                type="text"
                placeholder="Suchen..."
                value={this.state.value}
                className="search-input"
              />
              <input
                onClick={this.handleSubmit}
                className="search-submit"
                type="submit"
                value="Search"
              />
            </form>
          </div>
        </div>
        <div className="nav-lower flex spb center-v">
          <Dropdown id="p-dropdown" ref={this.pNav} options={options} onChange={this.productMenu} value={"Products"} placeholder="Products" />
          <span className="accent">/</span>
          <Link to="/en/automotive-solutions">
            <span>Automotive-/Industrial-Solutions</span>
          </Link>
          <span className="accent">/</span>
          <Link to="/en/lumex">
            <span>LUMEX</span>
          </Link>
          <span className="accent">/</span>
          <Link to="/en/distribution">
            <span>Distribution</span>
          </Link>
          <span className="accent">/</span>
          <Link to="/en/references">
            <span>References</span>
          </Link>
          <span className="accent">/</span>
          <Link to="/en/about-us">
            <span>About Us</span>
          </Link>
          <span className="accent">/</span>
          <Link to="/en/contact">
            <span>Contact</span>
          </Link>
        </div>
        <div className={"mob-menu " + this.state.open}>
          <div className="flex center-v center-h">
            <Link to="/">
              <span className="font-m">DE</span>
            </Link>
            <span className="font-m m-left-1">|</span>
            <span className="font-m m-left-1 active">EN</span>
          </div>
          <form className="flex center-h" onSubmit={this.handleSubmit}>
            <input
              type="text"
              placeholder="Search..."
              onChange={this.handleChange}
              className="search-input"
            />
            <input className="search-submit" type="submit" value="Search" />
          </form>
          <div className="mob-nav-links flex col">
            <Link className="nav-link" to="/en/products">
              <span>All Products</span>
            </Link>
            <Link className="nav-link" to="/en/product-overview">
              <span>Product Overview</span>
            </Link>
            <Link className="nav-link" to="/en/pcn">
              <span>Change Notifications</span>
            </Link>
            <Link className="nav-link" to="/en/automotive-solutions">
              <span>Automotive-/Industrial-Solutions</span>
            </Link>
            <Link className="nav-link" to="/en/lumex">
              <span>LUMEX</span>
            </Link>
            <Link className="nav-link" to="/en/distribution">
              <span>Distribution</span>
            </Link>
            <Link className="nav-link" to="/en/references">
              <span>References</span>
            </Link>
            <Link className="nav-link" to="/en/about-us">
              <span>About Us</span>
            </Link>
            <Link className="nav-link" to="/en/contact">
              <span>Contact</span>
            </Link>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavEN;
