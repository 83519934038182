import React, { Component } from "react";
import logo from "../../assets/pancon_logo_large.png";
import { Link } from "react-router-dom";

class ProductCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      this.props.lang === "de/produkte" ?
        <Link className="card-cont" to={"/" + this.props.lang + "/" + this.props.product.id}>
          <div className="card">
            <div className="card-left part-30 flex col center-v center-h">
              <img src={logo} width="64" height="64"></img>
              <span className="card-title">{this.props.product.fields.Name_Neu}</span>
            </div>
            <div className="card-divider"></div>
            <div className="card-right flex part-70">
              <div className="card-desc flex center-h col">
                <span className="prod-attr">{this.props.product.fields.Produktreihe}</span>
                <span className="prod-attr-desc">Produktreihe</span>
              </div>
              <div className="card-desc flex center-h col">
                <span className="prod-attr">{this.props.product.fields.Pitch}</span>
                <span className="prod-attr-desc">Pitch</span>
              </div>
              <div className="card-desc flex center-h col">
                <span className="prod-attr">{this.props.product.fields.Profile}</span>
                <span className="prod-attr-desc">Profil</span>
              </div>
              <div className="card-desc flex center-h col">
                <span className="prod-attr">{this.props.product.fields.Locating_Peg_DE}</span>
                <span className="prod-attr-desc">Positionierungsstift</span>
              </div>
              <div className="card-desc flex center-h col">
                <span className="prod-attr">{this.props.product.fields.Orientation}</span>
                <span className="prod-attr-desc">Orientierung</span>
              </div>
              <div className="card-desc flex center-h col">
                <span className="prod-attr">{this.props.product.fields.Connectivity_DE}</span>
                <span className="prod-attr-desc">Konnektivität</span>
              </div>
            </div>
          </div>
        </Link> : <Link className="card-cont" to={"/" + this.props.lang + "/" + this.props.product.id}>
          <div className="card">
            <div className="card-left part-30 flex col center-v center-h">
              <img src={logo} width="64" height="64"></img>
              <span className="card-title">{this.props.product.fields.Name_Neu}</span>
            </div>
            <div className="card-divider"></div>
            <div className="card-right flex part-70">
              <div className="card-desc flex center-h col">
                <span className="prod-attr">{this.props.product.fields.Produktreihe}</span>
                <span className="prod-attr-desc">Series</span>
              </div>
              <div className="card-desc flex center-h col">
                <span className="prod-attr">{this.props.product.fields.Pitch}</span>
                <span className="prod-attr-desc">Pitch</span>
              </div>
              <div className="card-desc flex center-h col">
                <span className="prod-attr">{this.props.product.fields.Profile}</span>
                <span className="prod-attr-desc">Profile</span>
              </div>
              <div className="card-desc flex center-h col">
                <span className="prod-attr">{this.props.product.fields.Locating_Peg_EN}</span>
                <span className="prod-attr-desc">Locating Peg</span>
              </div>
              <div className="card-desc flex center-h col">
                <span className="prod-attr">{this.props.product.fields.Orientation}</span>
                <span className="prod-attr-desc">Orientation</span>
              </div>
              <div className="card-desc flex center-h col">
                <span className="prod-attr">{this.props.product.fields.Connectivity_EN}</span>
                <span className="prod-attr-desc">Connectivity</span>
              </div>
            </div>
          </div>
        </Link>
    );
  }
}

export default ProductCard;
